@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap");

.send-newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 50px;
  margin-left: 50px;
  background-color: #fff;
  box-shadow: 0 0 10px #0000001a;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  width: 500px;

  h2 {
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 600;
    color: #333;
    font-size: 35px;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      margin-bottom: 20px;

      label {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
        color: #333;
        font-family: "Kumbh Sans", sans-serif;
        font-weight: 500;
      }

      input[type="text"],
      textarea {
        width: 96%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-family: "Roboto", sans-serif;

        &::placeholder {
          color: #c9bfbf;
          font-size: 14px;
          font-family: "Kumbh Sans", sans-serif;
        }
      }

      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }

    button {
      width: 190px;
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #00a5a2;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #008d8b;
      }
    }
  }

  p {
    margin-top: 20px;
    font-family: "Kumbh Sans", sans-serif;
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
}
