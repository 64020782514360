@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;500;700&display=swap");

.filter-travels-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-left: 40px;

  .filter-title {
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 600;
    color: #333;
    font-size: 35px;
    margin-bottom: 20px;
    text-align: left;
    width: 100%;
  }

  .filter-form {
    background-color: #fff;
    box-shadow: 0 0 10px #0000001a;
    border-radius: 8px;
    padding: 20px;
    width: 500px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: "Roboto", sans-serif;

    div {
      display: flex;
      flex-direction: column;

      label {
        margin-bottom: 10px;
        font-weight: 700;
        color: #333;
        font-family: "Kumbh Sans", sans-serif;
        font-weight: 500;
      }

      .input-filter,
      .input-checkbox {
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ddd;
        border-radius: 4px;
        width: 96%;
      }

      .input-filter::placeholder {
        color: #c9bfbf;
        font-size: 14px;
        font-family: "Kumbh Sans", sans-serif;
      }
    }

    .button-filter {
      width: 190px;
      padding: 10px 20px;
      font-size: 16px;
      color: #fff;
      background-color: #00a5a2;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #008d8b;
      }
    }
  }

  .travels-container {
    width: calc(100% - 700px);
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px #0000001a;
    border-radius: 8px;
    font-family: "Roboto", sans-serif;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    height: 500px;
    overflow-y: auto;

    .title-travel-result {
      text-align: left;
      margin-bottom: 20px;
      font-family: "Kumbh Sans", sans-serif;
      font-weight: 600;
      color: #333;
      font-size: 35px;
      grid-column: span 2;
    }

    .travel-card {
      background: #f9f9f9;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .travel-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;

        .travel-image {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-bottom: 15px;
        }

        .travel-name {
          font-weight: 700;
          color: #333;
        }

        .travel-description {
          display: block;
          color: #666;
          margin-top: 10px;
        }
      }

      .travel-actions {
        display: flex;
        gap: 10px;

        button {
          padding: 5px 10px;
          font-size: 14px;
          color: #fff;
          background-color: #00a5a2;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;

          &:hover {
            background-color: #008d8b;
          }
        }
      }
    }
  }
}
